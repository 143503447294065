@import url("https://fonts.googleapis.com/css?family=Exo:400,700");

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  background-color: lightslategray;
  font-family: sans-serif;
}
